import React from 'react'
import { formatPostDate } from "../../../utilities/dateFormat"

export default function BlogFull({ item, smartImageLoader }) {
  return (
    <div className={`content-row ${item.noPadding ? 'no-padding' : ''} ${item.type}-type`}>
      <div className="container">
        {
          item.wysiwygTitle && (
            <div dangerouslySetInnerHTML={{ __html: item.wysiwygTitle }}></div>
          )
        }
        {
          item.blog?.length > 0 && (
            <div>
              {
                item.blog.map((blog, index) => {
                  return (
                    <div className="blog-list-item" key={index}>
                      <div>
                        <a aria-label={`${blog.title}`} href={`${blog.uri}`}>
                          {
                            blog.featuredImage && blog.featuredImage.node.mediaDetails.sizes && (
                              <div className='image-wrapper'>
                                {
                                  smartImageLoader.renderImage(blog.featuredImage.node)
                                }
                              </div>
                            )
                          }
                          {
                            blog.featuredImage && !blog.featuredImage.node.mediaDetails.sizes && (
                              <div className='image-wrapper'>
                                {
                                  smartImageLoader.renderImage(blog.featuredImage.node)
                                }
                              </div>
                            )
                          }
                          {
                            !blog.featuredImage && rows.defaultImage?.mediaDetails && (
                              <div className='image-wrapper'>
                                {
                                  smartImageLoader.renderImage(rows.defaultImage)
                                }
                              </div>
                            )
                          }
                          {
                            !blog.featuredImage && !rows.defaultImage?.mediaDetails && (
                              <div className='image-wrapper'>
                                {
                                  smartImageLoader.renderImage(rows.defaultImage)
                                }
                              </div>
                            )
                          }
                        </a>
                      </div>
                      <div>
                        <a aria-label={`${blog.title}`} href={`${blog.uri}`}>
                          <h3>{blog.title}</h3>
                        </a>
                        <div className="blog-list-details">
                          <p>{formatPostDate(blog.date)}</p>
                          {
                            blog.categories.edges.length > 0 && (
                              <div>
                                <span>|</span>
                                {
                                  blog.categories.edges.map((cat, index) => {
                                    return (
                                      <a href={`${cat.node.uri}`} key={index}>
                                        {cat.node.name}<span className="sep">,&nbsp;</span>
                                      </a>
                                    )
                                  })
                                }
                              </div>
                            )
                          }
                          {
                            blog.blogPost?.excerpt && (
                              <div className="excerpt" dangerouslySetInnerHTML={{ __html: blog.blogPost.excerpt }}></div>
                            )
                          }
                        </div>
                        {
                          !blog.blogPost?.excerpt && (
                            <p dangerouslySetInnerHTML={{ __html: blog.blogPost.excerpt }}></p>
                          )
                        }
                      </div>
                    </div>
                  )
                })
              }
            </div>
          )
        }
      </div>
    </div>
  )
}