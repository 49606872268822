import React from 'react'
export default function SplitHeader({ item, smartImageLoader }) {
  return (
    <div className={`content-row ${item.type}-type`}>
      <div className="container">
        <div>
          <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
          <div>
            <div>
              {smartImageLoader.renderImage(item.image)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}