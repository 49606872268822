import React from 'react'
export default function Team({ item, toggleTeam, activeTeam, smartImageLoader }) {
  return (
    <div className={`container content-row ${item.noPadding ? 'no-padding' : ''} ${item.type}-type`}>
      <div>
        {
          item.wysiwygTitle && (
            <div dangerouslySetInnerHTML={{ __html: item.wysiwygTitle }}></div>
          )
        }
      </div>
      {
        item.team?.length > 0 && (
          <div>
            {
              item.team.map((team, index) => {
                return (
                  <div key={index}>
                    {
                      team.staff.biography && (
                        <>
                          <a href="#" className={`${activeTeam === index ? 'team-biography-toggle active' : 'team-biography-toggle'}`}
                            onClick={(e) => {
                              toggleTeam(e, index);
                            }}
                          >
                            <div className="team-details">
                              {
                                team.staff.image?.mediaDetails?.sizes?.length && (
                                  <div className='image-wrapper'>
                                    {
                                      smartImageLoader.renderImage(team.staff.image)
                                    }
                                  </div>
                                )
                              }
                              {
                                !team.staff.image?.mediaDetails?.sizes?.length && (
                                  <div className='image-wrapper'>
                                  {
                                    smartImageLoader.renderImage(team.staf.image)
                                  }
                                </div>
                                )
                              }
                              <h3>{team.title}</h3>
                              <div dangerouslySetInnerHTML={{ __html: team.staff.title }}></div>
                            </div>
                          </a>
                          <div className={`${activeTeam === index ? 'team-biography active' : 'team-biography'}`}>
                            <div dangerouslySetInnerHTML={{ __html: team.staff.biography }}></div>
                          </div>
                        </>
                      )
                    }
                    {
                      !team.staff.biography && (
                        <div className="team-details">
                          <div className='image-wrapper'>
                            {
                              smartImageLoader.renderImage(team.staff.image)
                            }
                          </div>
                          <h3>{team.title}</h3>
                          <div dangerouslySetInnerHTML={{ __html: team.staff.title }}></div>
                        </div>
                      )
                    }
                  </div>
                )
              })
            }
          </div>
        )
      }
    </div>
  )
}