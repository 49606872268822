import React from 'react'
export default function SplitContent({ item }) {
  return (
    <div className={`container large-container content-row ${item.noPadding ? 'no-padding' : ''} ${item.type}-type`}>
      {
        item.wysiwygTitle && (
          <div dangerouslySetInnerHTML={{ __html: item.wysiwygTitle }}></div>
        )
      }
      <div>
        {
          item.splitContent[0]?.content && (
            <div>
              <div dangerouslySetInnerHTML={{ __html: item.splitContent[0].content }}></div>
            </div>
          )
        }
        {
          item.splitContent[1]?.content && (
            <div>
              <div dangerouslySetInnerHTML={{ __html: item.splitContent[1].content }}></div>
            </div>
          )
        }
      </div>
    </div>
  )
}