import { useState, Fragment } from "react"
import dynamic from 'next/dynamic'
import Image from "next/image";
import Accordion from "./PageBuilder/ItemType/Accordion";
import BlogFull from "./PageBuilder/ItemType/BlogFull";
import CallToAction from "./PageBuilder/ItemType/CallToAction";
import CardWidget from "./PageBuilder/ItemType/CardWidget";
import Content from "./PageBuilder/ItemType/Content";
import FactSheet from "./PageBuilder/ItemType/FactSheet";
import Form from "./PageBuilder/ItemType/Form";
import Header from "./PageBuilder/ItemType/Header";
import IconWidget from "./PageBuilder/ItemType/IconWidget";
import ImageWidget from "./PageBuilder/ItemType/ImageWidget";
import LeftImageRightContent from "./PageBuilder/ItemType/LeftImageRightContent";
import RightImageLeftContent from "./PageBuilder/ItemType/RightImageLeftContent";
import SplitBlog from "./PageBuilder/ItemType/SplitBlog";
import SplitCallToAction from "./PageBuilder/ItemType/SplitCallToAction";
import SplitContent from "./PageBuilder/ItemType/SplitContent";
import SplitHeader from "./PageBuilder/ItemType/SplitHeader";
import TableColumn from "./PageBuilder/ItemType/TableColumn";
import Team from "./PageBuilder/ItemType/Team";
import ThreeColumnContent from "./PageBuilder/ItemType/ThreeColumnContent";
import Video from "./PageBuilder/ItemType/Video";

const DynamicCarousel = dynamic(() => import("./PageBuilder/ItemType/Carousel"))
const DynamicTestimonials = dynamic(() => import("./PageBuilder/ItemType/Testimonials"))

const SmartImageLoader = function () {
    let numberOfImages = 0
    return {
        renderImage: function (image, props = {}) {
            if (numberOfImages === 0) {
                props.priority = true
            }
            if (numberOfImages > 0) {
                props.priority = false
            }
            numberOfImages++
            return <OptimizeImage image={image} {...props} />
        }
    }
}

// https://nextjs.org/docs/pages/building-your-application/optimizing/images#image-sizing
const OptimizeImage = ({ image, priority = false }) => {
    return (
        <Image
            alt={`${image?.altText}`}
            src={`${image?.sourceUrl}`}
            priority={priority}
            fill
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
        />
    )
}

export default function PageBuilder(rows) {
    const [activeAccordion, setActiveAccordion] = useState('');
    const [activeTeam, setActiveTeam] = useState('');

    const toggleAccordion = (e, randomNumber) => {
        e.preventDefault();
        
        if(activeAccordion === randomNumber){
            setActiveAccordion('');
        } else {
            setActiveAccordion(randomNumber);
        }
    };
    
    const toggleTeam = (e, randomNumber) => {
        e.preventDefault();
        
        if(activeTeam === randomNumber){
            setActiveTeam('');
        } else {
            setActiveTeam(randomNumber);
        }
    };

    const smartImageLoader = SmartImageLoader();

    return (
        <div>
            {
                rows.rows && rows.rows.length > 0 && (
                    <div id="page-builder-container" className={`container-fluid ${rows.pageClass ? rows.pageClass : ''}`}>
                        {
                            rows.rows.map((item, index) => (
                                <Fragment key={index}>
                                {
                                    item.type === 'accordions' && (
                                        <div className={`container large-container content-row ${item.noPadding ? 'no-padding' : ''} ${item.type}-type`}>
                                             <div>
                                                {
                                                    item.wysiwygTitle && (
                                                        <div dangerouslySetInnerHTML={{__html: item.wysiwygTitle}}></div>
                                                    )
                                                }
                                            </div>
                                             {
                                                item.accordions && item.accordions.length > 0 && (
                                                    <div>
                                                        {
                                                            item.accordions.map( ( item, innerIndex ) =>{
                                                                return (
                                                                    <Accordion item={item} toggleAccordion={toggleAccordion} activeAccordion={activeAccordion} innerIndex={innerIndex} index={index} />
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                }
                                {
                                    item.type === 'blog-full' && (
                                        <BlogFull item={item} smartImageLoader={smartImageLoader} />
                                    )
                                }
                                {
                                    item.type === 'card-widgets' && (
                                        <CardWidget  item={item} />
                                    )
                                }
                                {
                                    item.type === 'call-to-action' && (
                                        <CallToAction  item={item} smartImageLoader={smartImageLoader} />
                                    )
                                }
                                {
                                    item.type === 'carousel' && (
                                        <DynamicCarousel item={item} smartImageLoader={smartImageLoader} />
                                    )
                                }
                                {
                                    item.type === 'content' && (
                                        <Content  item={item} />
                                    )
                                }
                                {
                                    item.type === 'fact-sheet' && (
                                        <FactSheet  item={item} smartImageLoader={smartImageLoader} />
                                    )
                                }
                                {
                                    item.type === 'form' && (
                                        <Form item={item} rows={rows} index={index} />
                                    )
                                }
                                {
                                    item.type === 'header' && (
                                        <Header item={item} smartImageLoader={smartImageLoader} />
                                    )
                                }
                                {
                                    item.type === 'icon-widgets' && (
                                        <IconWidget  item={item} smartImageLoader={smartImageLoader} />
                                    )
                                }
                                {
                                    item.type === 'image-widgets' && (
                                        <ImageWidget  item={item} smartImageLoader={smartImageLoader} />
                                    )
                                }
                                {
                                    item.type === 'left-image-right-content' && (
                                        <LeftImageRightContent item={item} smartImageLoader={smartImageLoader} />
                                    )
                                }
                                {
                                    item.type === 'right-image-left-content' && (
                                        <RightImageLeftContent item={item} smartImageLoader={smartImageLoader} />
                                    )
                                }
                                {
                                    item.type === 'split-call-to-action' && (
                                        <SplitCallToAction  item={item} smartImageLoader={smartImageLoader} />
                                    )
                                }
                                {
                                    item.type === 'split-blog' && (
                                        <SplitBlog  item={item} defaultImage={rows.defaultImage} smartImageLoader={smartImageLoader} />
                                    )
                                }
                                {
                                    item.type === 'split-content' && (
                                       <SplitContent  item={item} />
                                    )
                                }
                                {
                                    item.type === 'split-header' && (
                                        <SplitHeader  item={item} smartImageLoader={smartImageLoader}/>
                                    )
                                }
                                {
                                    item.type === 'table-columns' && (
                                        <TableColumn  item={item} />
                                    )
                                }
                                {
                                    item.type === 'team' && (
                                        <Team item={item} toggleTeam={toggleTeam} activeTeam={activeTeam} smartImageLoader={smartImageLoader} />
                                    )
                                }
                                {
                                    item.type === 'testimonials' && (
                                        <DynamicTestimonials item={item}/>
                                    )
                                }
                                {
                                    item.type === 'three-column-content' && (
                                        <ThreeColumnContent item={item} />
                                    )
                                }
                                {
                                    item.type === 'video' && (
                                        <Video  item={item} />
                                    )
                                }
                                </Fragment>
                            ))
                        }
                    </div>
                )
            }
        </div>
    );
  }