import { Fragment } from 'react';
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Header from '../components/Header';
import MinimalHeader from '../components/MinimalHeader';
import PageBuilder from '../components/PageBuilder';
import LargeCTA from '../components/LargeCTA';
import CTA from '../components/CTA';
import { client } from '../lib/apollo';
import parse from "html-react-parser";

import { GET_PAGE, GET_PAGE_OPTIONS, GET_404_DATA, GET_HEADER_OPTIONS, GET_FOOTER_OPTIONS,
  GET_BLOG_FEATURED_IMAGE } from '../queries/queries';

import { useState, useEffect} from "react";
import Layout from '../components/Layout';
import Script from 'next/script';
import Cookies from 'js-cookie';

const DynamicFooter = dynamic(() => import('../components/Footer'))
const DynamicMinimalFooter = dynamic(() => import('../components/MinimalFooter'))

export default function SlugPage({ pageData }) {
  const [keys, setKeys] = useState([]);
  const pageTitle = pageData?.page ? pageData?.page.title : '404';

  const seoFullHead = pageData?.page?.seo?.fullHead
  const fullHead = seoFullHead ? parse(seoFullHead) : <Fragment />;

  const page = pageData?.page;
  const headerData = pageData?.headerData;
  const footerData = pageData?.footerData;
  const pageOptions = pageData?.pageOptions?.data?.pageBy?.pageOptions;

  const largeCTA = pageOptions?.largeCta ? pageOptions.largeCta[0].largeCta : null;
  const cta = pageOptions?.callToAction ? pageOptions.callToAction[0].cta : null;

  const defaultImage = pageData?.blogDefaultImage?.data?.siteOptions?.siteOptions?.defaultImage;
  const formSecret = pageData?.blogDefaultImage?.data?.siteOptions?.siteOptions?.formSecretToken;
  const formUrl = pageData?.blogDefaultImage?.data?.siteOptions?.siteOptions?.backendUrl;

  const errorContent = pageData?.noPageData?.data?.siteOptions?.siteOptions?.errorContent;

  useEffect(() => {
    if(Cookies.get('blog-end-cursor')){
        Cookies.remove('blog-end-cursor');
    }
  }); 

  return (
    <Layout>
      <div>
        <Script defer={true}>
          {`window.semaphore=window.semaphore||[],window.ketch=function(){window.semaphore.push(arguments)};var e=new URLSearchParams(document.location.search),o=e.has("property")?e.get("property"):"website_smart_tag",n=document.createElement("script");n.type="text/javascript",n.src="https://global.ketchcdn.com/web/v2/config/the_signatry/".concat(o,"/boot.js"),n.defer=n.async=!0,document.getElementsByTagName("head")[0].appendChild(n)`}
        </Script>
        <Script defer={true}>
          {`(function (w, d, s, l, i) {
                    w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
                    var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
                    j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
                })(window, document, 'script', 'dataLayer', 'GTM-5QQ4SLR');`}
        </Script>
        <Head>
          {fullHead}
          <meta name="MobileOptimized" content="width" />
          <meta name="HandheldFriendly" content="True"/>
          <meta name="google-site-verification" content="tU-GsioR3Mk4hNjgTZ9gxHzP3zqHy7SWK_bvBbehYbE" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0" />
          <title>{`The Signatry | ${pageTitle}`}</title>
          <link rel="icon" href="/assets/favicon/favicon.ico"></link>
          <link rel='preconnect' href="https://o4505726736138240.ingest.sentry.io" />
          <Script src="https://cdn.jsdelivr.net/npm/swiper@9/swiper-element-bundle.min.js" />
          {fullHead}
        </Head>
        {
          !page && (
            <Header header={headerData}></Header>
          )
        }
        {
          page && page.pageOptionsNotHome && !page.pageOptionsNotHome.hideHeaderAndFooter && (
              page?.pageOptionsNotHome && !page.pageOptionsNotHome.minimalHeaderAndFooter && (
                <Header header={headerData}></Header>
              ) 
          )
        }
        {
          page && page.pageOptionsNotHome && !page.pageOptionsNotHome.hideHeaderAndFooter && (
              page?.pageOptionsNotHome && page.pageOptionsNotHome.minimalHeaderAndFooter && (
                <MinimalHeader></MinimalHeader>
              ) 
          )
        }
        <div id="content" role="main">
          {
            page && (
              <PageBuilder
                rows={page.pageBuilder?.contentRows}
                defaultImage={defaultImage}
                pageClass={page?.pageOptionsNotHome && page.pageOptionsNotHome.hideHeaderAndFooter ? 'minimal-page' : '' }
                keys={keys}
                formSecret={formSecret}
                formUrl={formUrl}
              ></PageBuilder>
            )
          }
          {
            !page && (
              <div id="error-container" className='container'>
                <div dangerouslySetInnerHTML={{__html: errorContent}}></div>
              </div>
            )
          }
          {
            page && page.pageOptionsNotHome 
                 && (!page.pageOptionsNotHome.hideHeaderAndFooter 
                 && !page.pageOptionsNotHome.minimalHeaderAndFooter)
                 && (
              <>
                <LargeCTA
                largeCTA={largeCTA}
                ></LargeCTA>
                <CTA
                cta={cta}
                ></CTA>
              </>
            )
          }
        </div>
        {
          !page && (
            <DynamicFooter footer={footerData}></DynamicFooter>
          )
        }
        {
          page && 
          page.pageOptionsNotHome && 
          !page.pageOptionsNotHome.hideHeaderAndFooter && 
          !page.pageOptionsNotHome.minimalHeaderAndFooter && (
            <DynamicFooter footer={footerData}></DynamicFooter>
          )
        }
        {
          page && page.pageOptionsNotHome && !page.pageOptionsNotHome.hideHeaderAndFooter && page.pageOptionsNotHome.minimalHeaderAndFooter && (
            <DynamicMinimalFooter></DynamicMinimalFooter>
          )
        }
      </div>
    </Layout>
  )
}

export async function getStaticProps({ params }){
  const slug = params.uri.join("/");

  const response = await client.query({
    query: GET_PAGE,
    variables: { slug: slug }
  });

  const page = response?.data?.pageBy;

  let pageOptions = {};

  if(page){ 
    pageOptions = await client.query({
      query: GET_PAGE_OPTIONS,
      variables: { slug: page.uri }
    });
  }

  const headerData = await client.query({
      query: GET_HEADER_OPTIONS
  });

  const blogDefaultImage = await client.query({
    query: GET_BLOG_FEATURED_IMAGE
  });

  const footerData = await client.query({
    query: GET_FOOTER_OPTIONS
  });

  const noPageData = await client.query({
    query: GET_404_DATA
  });

  return {
    props: {
      pageData: {
        page: page,
        pageOptions: pageOptions,
        noPageData: noPageData,
        blogDefaultImage: blogDefaultImage,
        headerData: headerData,
        footerData: footerData
      }
    }
  }
}

export async function getStaticPaths(){
    const paths = [];

    return {
        paths,
        fallback: 'blocking'
    }
}