export default function MinimalHeader() {
  return (
    <>
        <div id="header-container" className="container xlarge-container minimal-header">
            <div>
                <a href="/">
                    <img src="/assets/images/logo.svg" alt="The Signatry" />
                </a>
            </div>
        </div>
    </>
  );
}