import React from 'react'
export default function Accordion({ item, toggleAccordion, innerIndex, activeAccordion, index }) {
  return (
    <div className="accordion" key={innerIndex}>
      <a href="#" className={`${activeAccordion === innerIndex + (200 * index) + index ? 'accordion-header active' : 'accordion-header'}`}
        onClick={(e) => {
          toggleAccordion(e, innerIndex + (200 * index) + index);
        }}
      >
        <h3>{item.title}</h3>
      </a>
      <div className={`${activeAccordion === innerIndex + (200 * index) + index ? 'accordion-content active' : 'accordion-content'}`}>
        <div dangerouslySetInnerHTML={{ __html: item.details }}></div>
      </div>
    </div>
  )
}
