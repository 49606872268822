import React from 'react'
export default function ThreeColumnContent({ item }) {
  return (
    <div className={`container large-container content-row ${item.noPadding ? 'no-padding' : ''} ${item.type}-type`}>
      {
        item.wysiwygTitle && (
          <div dangerouslySetInnerHTML={{ __html: item.wysiwygTitle }}></div>
        )
      }
      <div>
        {
          item.threeColumnContent.map((column, index) => {
            return (
              <div>
                <div dangerouslySetInnerHTML={{ __html: column.content }}></div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}