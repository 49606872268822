import React from 'react'
export default function Content({ item }) {
  return (
    <div className={`container-fluid content-row ${item.noPadding ? 'no-padding' : ''} ${item.type}-type ${item.backgroundColor}-color`} >
      {
        item.narrow && (
          <div className="container xsmall-container">
            <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
          </div>
        )
      }
      {
        !item.narrow && (
          <div className="container large-container">
            <div dangerouslySetInnerHTML={{ __html: item.content }}></div>

          </div>
        )
      }
    </div>
  )
}