import React from 'react'
export default function Header({ item, smartImageLoader }) {
  return (
    <div className={`content-row ${item.type}-type`}>
      <div className="container large-container">
        <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
        {
          item.image && (
            <div className='image-wrapper'>
              {
                smartImageLoader.renderImage(item.image)
              }
            </div>
          )
        }
      </div>
    </div>
  )
}