import React from 'react'
export default function TableColumn({ item }) {
  let totalText = 0;

  if(item.tableHeader.length === 1){
    totalText = 'one-count';
  } else if(item.tableHeader.length === 2){
    totalText = 'two-count';
  } else if(item.tableHeader.length === 3){
    totalText = 'three-count';
  } else if(item.tableHeader.length === 4){
    totalText = 'four-count';
  } else if(item.tableHeader.length === 5){
    totalText = 'five-count';
  }

  return (
    <div className={`content-row ${item.noPadding ? 'no-padding' : ''} ${item.type}-type ${item.backgroundColor}-background`}>
      <div className='container'>
        {
          item.tableHeader && item.tableHeader.length > 0 && (
            <div className={`table-header ${totalText}`}>
              {
                item.tableHeader.map((tableHeader, index) => {
                  return (
                    <div key={index}>
                      <h3>{tableHeader.content}</h3>
                    </div>
                  )
                })
              }
            </div>
          )
        }
        {
          item.tableRows && item.tableRows.length > 0 && (
            item.tableRows.map((tableCol, index) => {
              return (
                <div className={`table-row ${totalText}`} key={index}>
                  {
                    tableCol.rowContent && tableCol.rowContent.length > 0 && (
                      tableCol.rowContent.map((content, index) => {
                        return (
                          <div>
                            <div dangerouslySetInnerHTML={{ __html: content.content }}></div>
                          </div>
                        )
                      })
                    )
                  }
                </div>
              )
            })
          )
        }
      </div>
    </div>
  )
}