import React from 'react'
export default function IconWidget({ item }) {
  return (
    <div className={`content-row ${item.noPadding ? 'no-padding' : ''} row-${item.type}-type`}>
      <div className="container large-container">
        <div>
          {
            item.wysiwygTitle && (
              <div dangerouslySetInnerHTML={{ __html: item.wysiwygTitle }}></div>
            )
          }
        </div>
        <div>
          {
            item.iconWidgets?.length > 0 && (
              item.iconWidgets.map((iconWidget, index) => {
                return (<div className="row-icon-widget" key={index}>
                  <img alt={`${iconWidget.image.altText}`} src={`${iconWidget.image.sourceUrl}`} />
                  <div className="content">
                    <div dangerouslySetInnerHTML={{ __html: iconWidget.content }}></div>
                  </div>
                </div>
                )
              })
            )
          }
        </div>
      </div>
    </div>
  )
}