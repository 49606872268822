import React from 'react'
export default function CardWidget({ item }) {
  return (
    <div className={`container content-row ${item.noPadding ? 'no-padding' : ''} ${item.type}-type`}>
      {
        item.cardWidgets && item.cardWidgets.length > 0 && (
          <div>
            {
              item.cardWidgets.map((cardWidget, index) => {
                return (
                  <div key={index}>
                    <div className="title">
                      <h3>{cardWidget.title}</h3>
                    </div>
                    <div className="content" dangerouslySetInnerHTML={{ __html: cardWidget.content }}></div>
                  </div>
                )
              })
            }
          </div>
        )
      }
    </div>
  )
}