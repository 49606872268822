import React from 'react'
export default function SplitCallToAction({ item, smartImageLoader }) {
  return (
    <div className={`container content-row ${item.noPadding ? 'no-padding' : ''} ${item.type}-type`}>
      {
        item.callToActionLocation == 'left' && (
          <div>
            {
              item.backgroundImage && (
                <div className="image">
                  <div className='image-wrapper'>
                    {
                      smartImageLoader.renderImage(item.backgroundImage)
                    }
                  </div>
                  {
                    item.callToActionContent && (
                      <div dangerouslySetInnerHTML={{ __html: item.callToActionContent }}></div>
                    )
                  }
                </div>
              )
            }
            {
              !item.backgroundImage && (
                <div className="no-image">
                  {
                    item.callToActionContent && (
                      <div dangerouslySetInnerHTML={{ __html: item.callToActionContent }}></div>
                    )
                  }
                </div>
              )
            }
            <div>
              {
                item.content && (
                  <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                )
              }
            </div>
          </div>
        )
      }
      {
        item.callToActionLocation == 'right' && (
          <div>
            <div>
              {
                item.content && (
                  <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                )
              }
            </div>
            {
              item.backgroundImage && (
                <div className="image">
                  <div className='image-wrapper'>
                    {
                      smartImageLoader.renderImage(item.backgroundImage)
                    }
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: item.callToActionContent }}></div>
                </div>
              )
            }
            {
              !item.backgroundImage && (
                <div className="no-image">
                  <div dangerouslySetInnerHTML={{ __html: item.callToActionContent }}></div>
                </div>
              )
            }
          </div>
        )
      }
    </div>
  )
}