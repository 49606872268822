import React from 'react'
export default function CallToAction({ item, smartImageLoader }) {
  return (
    <div className={`container content-row ${item.noPadding ? 'no-padding' : ''} ${item.type}-type`}>
      {
        item.backgroundImage && (
          <div className="image">
            <div className='image-wrapper'>
              {
                smartImageLoader.renderImage(item.backgroundImage)
              }
            </div>
            {
              item.callToActionContent && (
                <div dangerouslySetInnerHTML={{ __html: item.callToActionContent }}></div>
              )
            }
          </div>
        )
      }
      {
        !item.backgroundImage && (
          <div className="no-image">
            {
              item.callToActionContent && (
                <div dangerouslySetInnerHTML={{ __html: item.callToActionContent }}></div>
              )
            }
          </div>
        )
      }
    </div>
  )
}