import React from 'react'
export default function FactSheet({ item, smartImageLoader }) {
  return (
    <div className={`container content-row ${item.noPadding ? 'no-padding' : ''} ${item.type}-type`}>
      {
        item.wysiwygTitle && (
          <div dangerouslySetInnerHTML={{ __html: item.wysiwygTitle }}></div>
        )
      }
      {
        item.factsheets && item.factsheets.length > 0 && (
          <div>
            {
              item.factsheets.map((factSheet, index) => {
                return (<div className="factsheet" key={index}>
                  {
                    factSheet.image && !factSheet.image?.sourceUrl && (
                      <div className={`image-wrapper ${factSheet.iconImage ? 'icon-image' : ''}`}>
                          {
                            smartImageLoader.renderImage(factSheet.image)
                          }
                        </div>
                    )
                  }
                  {
                    factSheet.image && factSheet.image?.sourceUrl && (
                      <div className={`image-wrapper ${factSheet.iconImage ? 'icon-image' : ''}`}>
                          {
                            smartImageLoader.renderImage(factSheet.image)
                          }
                        </div>
                    )
                  }
                  <div className="content">
                    <div dangerouslySetInnerHTML={{ __html: factSheet.content }}></div>
                  </div>
                </div>
                )
              })
            }
          </div>
        )
      }
    </div>
  )
}