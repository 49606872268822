import React from 'react'
export default function RightImageLeftContent({ item, smartImageLoader }) {
  return (
    <div className={`content-row ${item.noPadding ? 'no-padding' : ''} ${item.smallImage ? 'small-image ' : ''}${item.backgroundColor}-color ${item.type}-type`}>
      <div className="container">
        <div>
          <div>
            {
              item.content && (
                <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
              )
            }
          </div>
          <div>
            {
              item.image && (
                <>
                  {
                    item.smallImage && item.image?.mediaDetails.length && (
                      item.image?.mediaDetails?.sizes?.map((image, index) => {
                        return (
                          image.width === '740' && image.height === '860' && (
                            <div className={`image-wrapper ${item.containImage ? 'contain-image' : ''}`}>
                              {
                                smartImageLoader.renderImage(image)
                              }
                            </div>
                          )
                        )
                      })
                    )
                  }
                  {
                    item.smallImage && !item.image?.mediaDetails.length && (
                      <div className={`image-wrapper ${item.containImage ? 'contain-image' : ''}`}>
                        {
                          smartImageLoader.renderImage(item.image)
                        }
                      </div>
                    )
                  }
                  {
                    !item.smallImage && item.image?.mediaDetails.length && (
                      item.image?.mediaDetails?.sizes?.map((image, index) => {
                        return (
                          image.width === '1140' && image.height === '960' && (
                            <div className={`image-wrapper ${item.containImage ? 'contain-image' : ''}`}>
                              {
                                smartImageLoader.renderImage(image)
                              }
                            </div>
                          )
                        )
                      })
                    )
                  }
                  {
                    !item.smallImage && !item.image?.mediaDetails.length && (
                      <div className={`image-wrapper ${item.containImage ? 'contain-image' : ''}`}>
                        {
                          smartImageLoader.renderImage(item.image)
                        }
                      </div>
                    )
                  }
                </>
              )
            }
            {
              !item.image && item.video && (
                <div className="video-wrapper">
                  <div dangerouslySetInnerHTML={{ __html: item.video }}></div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}